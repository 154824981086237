import * as React from 'react';
import { CRModal } from '@coverright/ui/shared';
import { Props } from '../lib/types';
import { Box } from '@mui/material';
import { ModalCloseButton } from '@coverright/shared/ui';
import { PDPDrugCostBreakdown } from './pdp-drug-cost-breakdown';

interface ModalProps extends Props {
  clientId?: string;
  open: boolean;
  onClose: () => void;
}

export function PdpDrugCostBreakdownModal(props: ModalProps) {
  const { open, onClose, ...rest } = props;
  return (
    <CRModal
      paperSx={{
        width: { xs: '100%', lg: '75%' },
        minWidth: { md: 800 },
        maxWidth: 1100,
      }}
      open={open}
      onClose={onClose}
      paperPadding={{ xs: 2, md: 4 }}
      disableSidePadding
    >
      <Box sx={{ position: 'relative' }}>
        <ModalCloseButton
          sx={{ position: 'absolute', right: -8, top: -32 }}
          onClick={onClose}
        />
        <PDPDrugCostBreakdown {...rest} />
      </Box>
    </CRModal>
  );
}
